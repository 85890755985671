import React from 'react'
import Helmet from 'react-helmet'
import { Container, Card, CardText, CardBody, CardTitle, CardSubtitle } from 'reactstrap'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

const sandwichesPage = ({ data }) => {
  const posts = data.allMarkdownRemark.edges.filter(post => !post.node.frontmatter.hidden && post.node.frontmatter.contentType === 'sandwiches')
  return (
    <Layout>
      <Helmet>
        <title>Συσκευασμένα κρύα σάντουις | Σπιτικό Παπακωνσταντίνου</title>
        <meta name="description" content="Συσκευασμένα κρύα σάντουιτς | Σπιτικό Παπακωνσταντίνου" />
      </Helmet>
      <Container className="product-archive sandwitches">
        <h1><b>Συσκευασμένα κρύα σάντουιτς</b></h1>
        <p>Το προιόν μας δεν είναι απλά ένα <b>σάντουιτς</b>… 
        Τα αρτοσκεύασματα μας ειναι <b>προιόντα παραδοσιακών φούρνων</b> και όχι βιομηχανικά. Τα <b>αλλαντικά</b> και <b>τυροκομικά</b> που χρησιμοποιούμε είναι <b>αρίστης ποιότητας</b>. Όλες οι <b>πρώτες</b> ύλες μας είναι <b>πιστοποιημένα προιόντα</b>, τα οποία <b>ελέγχονται</b> τακτικά με <b>μικροβιολογικές αναλύσεις</b>, καθώς και τα τελικά προιόντα μας ελέγχονται επίσης για την <b>διασφάλιση ποιότητας</b> τους.</p>
        <p><Link to="/epikoinonia">Μην διστάσετε να επικοινωνήσετε μαζι μας</Link></p>
        {posts.map(({ node: post }) => (
          <Link to={post.frontmatter.path}>
            <Card style={{ marginBottom: 10 }} key={post.id}>
              <CardBody>
                <img className="featuredImage" src={post.frontmatter.thumbnail} alt={post.frontmatter.title} />
                <CardTitle>{post.frontmatter.title}</CardTitle>
                <CardSubtitle style={{ marginBottom: 10 }}>{post.frontmatter.date}</CardSubtitle>
                <CardText>{post.excerpt}</CardText>
              </CardBody>
              <span className="scat">{post.frontmatter.category}</span>
            </Card>
          </Link>
        ))}

      </Container>
    </Layout>
  )
}


export default sandwichesPage

export const sandwichesQuery = graphql`
  query sandwichesQuery {
    allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___category] }) {
      edges {
        node {
          excerpt(pruneLength: 200)
          id
          frontmatter {
            title
            contentType
            category
            thumbnail
            date(formatString: "DD-MM-YYYY")
            path
            hidden
          }
        }
      }
    }
  }
`
